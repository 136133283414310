var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-fn table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped table-bordered table-sm table-responsive-sm"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Customer Account")]), _c('th', [_vm._v("inventLocationId")]), _c('th', [_vm._v("Account Type ")]), _c('th', [_vm._v("Account Subtype")]), _c('th', [_vm._v("Vendor")]), _c('th', [_vm._v("Amount")]), !_vm.isGroup ? _c('th', [_vm._v(" Transaction Date ")]) : _vm._e(), _c('th', [_vm._v("Period")]), _vm.isGroup ? _c('th', [_vm._v("Detail")]) : _c('th', [_vm._v("Description")]), !_vm.isGroup ? _c('th', [_vm._v(" Detail Duration ")]) : _vm._e(), _c('th', [_vm._v(" Download ")])])]), !_vm.isLoading ? _c('tbody', [_vm._l(_vm.items, function (item, index) {
    return [_c('tr', {
      key: item.id
    }, [_c('td', [_vm._v(_vm._s((_vm.currentPage - 1) * _vm.perPage + (index + 1)))]), _c('td', [_vm._v(_vm._s(_vm.accountID))]), _c('td', [_vm._v(_vm._s(_vm.inventLocationId))]), _c('td', [_vm._v(_vm._s(_vm.accountType))]), _c('td', {
      staticClass: "text-center"
    }, [item.offer_type == 'single' ? _c('div', [_c('span', {
      staticClass: "badge badge-primary p-1"
    }, [_c('i', {
      staticClass: "fa fa-tag"
    }), _vm._v(" " + _vm._s(item.offer_type) + " ")])]) : _vm._e(), item.offer_type == 'bundle' ? _c('div', [_c('span', {
      staticClass: "badge badge-yellow p-1"
    }, [_c('i', {
      staticClass: "fa fa-tag"
    }), _vm._v(" " + _vm._s(item.offer_type) + " ")])]) : _vm._e(), item.offer_type == 'subscription' ? _c('div', [_c('span', {
      staticClass: "badge badge-danger"
    }, [_c('i', {
      staticClass: "fa fa-tag"
    }), _vm._v(" " + _vm._s(item.offer_type) + " ")])]) : _vm._e(), item.offer_type == 'buffet' ? _c('div', [_c('span', {
      staticClass: "badge badge-purple p-1"
    }, [_c('i', {
      staticClass: "fa fa-diamond"
    }), _vm._v(" " + _vm._s(item.offer_type) + " ")])]) : _vm._e()]), _c('td', {
      staticClass: "text-left"
    }, [_vm._v(_vm._s(item.vendor_name))]), !_vm.isGroup ? _c('td', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(item.disbursement, "IDR")) + " ")]) : _c('td', [_vm._v(" " + _vm._s(_vm.formatPrice(item.total, "IDR")) + " ")]), !_vm.isGroup ? _c('td', [_vm._v(" " + _vm._s(_vm.formatDate(item.disbursement_date)) + " ")]) : _vm._e(), _c('td', {
      staticStyle: {
        "width": "17%"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatDate(item.disbursement_period)) + " ")]), _vm.isGroup ? _c('td', [_c('router-link', {
      staticClass: "btn btn-sm btn-outline-dark mr-2",
      attrs: {
        "to": {
          path: ("/subledger/vendor/" + (item.vendor_id) + "?offer_type=" + (item.offer_type) + "&period=" + (item.disbursement_period))
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-list-alt"
    }), _vm._v(" View ")])], 1) : _c('td', [_vm._v(" " + _vm._s(item.description) + " ")]), !_vm.isGroup ? _c('td', [_c('router-link', {
      staticClass: "btn btn-sm btn-dark",
      attrs: {
        "to": {
          path: ("/reading-buffets/" + (item.transaction_code) + "/vendor/" + (item.vendor_id))
        }
      }
    }, [_vm._v(" View Durasi ")])], 1) : _vm._e(), _c('td', [_c('button', {
      staticClass: "btn btn-sm btn-outline-primary",
      attrs: {
        "disabled": _vm.isDownload
      },
      on: {
        "click": function ($event) {
          return _vm.onDownload(item, index);
        }
      }
    }, [_vm.isDownload && index == _vm.current_row ? _c('b-spinner', {
      staticClass: "align-middle",
      staticStyle: {
        "width": "20px",
        "height": "20px"
      }
    }) : _c('i', {
      staticClass: "fa fa-download"
    }), _vm._v(" Download ")], 1)])])];
  })], 2) : _c('tbody', [[_c('tr', [_c('td', {
    attrs: {
      "colspan": "10"
    }
  }, [_c('div', {
    staticClass: "text-center text-danger my-2"
  }, [_c('b-spinner', {
    staticClass: "align-middle"
  }), _c('strong', [_vm._v(" Please Wait...")])], 1)])])]], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }