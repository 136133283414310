<template>
  <div class="table-fn table-responsive">
    <table  class="table table-striped table-bordered table-sm table-responsive-sm">
      <thead>
        <tr>
          <th>No</th>
          <th>Customer Account</th>
          <th>inventLocationId</th>
          <th>Account Type </th> 
          <th>Account Subtype</th>
          <th>Vendor</th>
          <th>Amount</th>
          <th v-if="!isGroup">
            Transaction Date
          </th>
          <th>Period</th>
          <th v-if="isGroup">Detail</th>
          <th v-else>Description</th>
          <th v-if="!isGroup">
            Detail Duration
          </th>
          <th> Download </th>
        </tr>
      </thead>
      <tbody v-if="!isLoading">
        <template v-for="(item, index) in items">
          <tr :key="item.id">
            <td>{{ (currentPage - 1) * perPage + (index + 1) }}</td>
            <td>{{ accountID }}</td>
            <td>{{ inventLocationId }}</td>
            <td>{{ accountType }}</td>
            <td class="text-center">
              <div v-if="item.offer_type == 'single'">
                <span class="badge badge-primary p-1">
                  <i class="fa fa-tag"></i> {{ item.offer_type }}
                </span>
              </div>
              <div v-if="item.offer_type == 'bundle'">
                <span class="badge badge-yellow p-1"> 
                  <i class="fa fa-tag"></i> {{ item.offer_type }}
              </span>
              </div>
              <div v-if="item.offer_type == 'subscription'">
                <span class="badge badge-danger">
                  <i class="fa fa-tag"></i> {{ item.offer_type }}
                </span>
              </div>
              <div v-if="item.offer_type == 'buffet'">
                <span class="badge badge-purple p-1">
                  <i class="fa fa-diamond"></i> 
                  {{ item.offer_type }}
                </span>
              </div>
            </td>
            <td class="text-left">{{ item.vendor_name }}</td>
            <td v-if="!isGroup" class="text-right">
              {{ formatPrice(item.disbursement, "IDR") }}
            </td>
            <td v-else>
              {{ formatPrice(item.total, "IDR") }}
            </td>
            <td v-if="!isGroup">
               {{ formatDate(item.disbursement_date) }}
            </td>
            <td style="width:17%">
              {{ formatDate(item.disbursement_period) }}
            </td>
            <td v-if="isGroup">
              <router-link :to="{ 
                path: `/subledger/vendor/${item.vendor_id}?offer_type=${item.offer_type}&period=${item.disbursement_period}`}" 
                class="btn btn-sm btn-outline-dark mr-2">
                <i class="fa fa-list-alt"> </i>
                View 
              </router-link>
            </td>
            <td v-else>
              {{ item.description }}
            </td>
            <td v-if="!isGroup">
              <router-link :to="{ 
                path: `/reading-buffets/${item.transaction_code}/vendor/${item.vendor_id}`}" 
                class="btn btn-sm btn-dark">
                View Durasi
              </router-link>
            </td>
            <td>
              <button @click="onDownload(item, index)" class="btn btn-sm btn-outline-primary" :disabled="isDownload">
                <b-spinner style="width: 20px; height: 20px;" class="align-middle" v-if="isDownload && index==current_row"></b-spinner>
                <i class="fa fa-download" v-else></i> Download
              </button>
            </td>
          </tr>
        </template>
      </tbody>
      <tbody v-else>
        <template>
          <tr>
            <td colspan="10">
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Please Wait...</strong>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
   
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
  export default {
    name: 'SubledgerTable',
    props: {
      items:{
        type: Array,
        default: () => []
      },
      isGroup: {
        type: Boolean,
        default: true
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      totalRows: {
        type: Number,
        default: 0
      },
      accountID: {
        type: String,
        default: "C-002829808"
      },
      inventLocationId:{
        type: String,
        default: "10198"
      },
      accountType: {
        type: String,
        default: "Revenue"
      },
      perPage: {
        type: Number,
        default: 10
      
      },
      currentPage: {
        type: Number,
        default: 1
      }
    },
    data () {
      return {
        fields: [
          {
            key: "vendor_name",
            label: "Vendor",
            sortable: true,
            sortDirection: "asc"
          },
          {
            key: "offer_type",
            label: "Report Type",
            sortable: true,
            sortDirection: "asc"
          },
          {
            key: "total",
          }
        ]
      }
    },
    computed: {
      ...mapState({
        isDownload: (state) => state.subledger.isDownload,
      }),
    },
    methods: {
      ...mapActions('subledger',['sendReportByPeriod']),
      formatPrice(value, currency) {
        if (value) {
          var formatter = null;
          if (currency == "USD") {
            formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            });
          } else {
            formatter = new Intl.NumberFormat("en-ID", {
              style: "currency",
              currency: currency,
            });
          }

          return formatter.format(value);
        }

        return "0";
      },
      formatDate(date) {
        return moment(date).format("DD MMMM YYYY");
      },
      onDownload(row, idx) {
        console.log('rowData', row);
        this.current_row = idx;
        const payload = {
          vendor_id: row.vendor_id,
          period: moment(row.disbursement_period).format("YYYY-MM-DD"),
        }
        this.sendReportByPeriod(payload);
      },
    },
    
  }
</script>

<style lang="css" scoped>
.table-fn {
  font-family:"Avenir Next"
}
</style>