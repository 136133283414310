<template>
  <div class="rows books">
    <div class="card mt-2 pt-2">
      <h2 class="title h2 text-center">Subledger</h2>
      <div class="card-body">
        <b-alert show>
          Laporan total penjualan dari semua offers (saat ini termasuk .com, digital, eperpus). Laporan dikelompokan berdasarkan vendor, offer type & periode.
        </b-alert>
        <div class="col-md-12 pt-2">
          <div class="form-group row">
            <span class="col-sm-1">Select Range</span>
            <div class="col-md-auto">
              <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                :max-date="new Date()"
                is-range
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div class="rows">
                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      :max-date="new Date()"
                    />

                    <span class="p-2">to</span>

                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      :max-date="new Date()"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="col-md-auto">
              <button class="btn btn-block btn-primary" @click="actionSearch">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group row">
            <span class="col-sm-1">Ledger</span>
            <div class="col-md-auto">
             <select class="form-control-sm" v-model="Filter.ledger">
                <option value="revenue">Revenue</option>
                <option value="payment-gateway" disabled>PaymentsGateway</option>
              </select>
            </div>
            <span class="col-sm-auto">Account Subtype</span>
            <div class="col-md-auto">
              <select v-model="Filter.offer_type" class="form-control-sm">
                <option key=""> ALL</option>
                <option v-for="offer in Offers" :key="offer.value" :value="offer.name">
                  {{offer.name}}
                </option>
              </select>
            </div>
            
          </div>
          <div class="form-group row">
            <span class="col-sm-1">Publisher</span>
            <div class="col-md-4">
              <multiselect v-model="Filter.vendor_id" 
                :options="vendors" 
                placeholder="Select one" 
                label="Vendor Name" 
                :close-on-select="true"
                :custom-label="customLabel"
                @search-change="asyncFind"
                :searchable="true"
                :loading="isLoadingSearch"
                track-by="name">
              </multiselect>
            </div>
            <div class="col text-right">
              <!-- <button
                class="ml-1 btn btn-outline-success btn-sm"
                @click="downloadReport"
              >
                <span class="fa fa-download mx-1"  v-if="!isDownload"></span>
                <b-spinner style="width: 20px; height: 20px;" class="align-middle" v-else></b-spinner>

              </button> -->
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <TableSubledger :items="items" 
            :totalRows="parseInt(totalRows)" 
            :isLoading="isLoading" 
            :isGroup="true"
            :perPage="perPage"
            :currentPage="currentPage"/>
           <b-row class="justify-content-start">
            <b-col lg="3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions} from 'vuex'
import TableSubledger from "./table.vue"
import moment from "moment";
  export default {
    name: 'Subledger',
    components: {
      TableSubledger
    },
    data () {
      return {
        range: {
          start: moment().startOf('month').subtract(1,'M').format("YYYY-MM-DD"),
          end: moment().subtract(1,'M').endOf('month').format("YYYY-MM-DD"),
        },
        masks: {
          input: "YYYY-MM-DD",
        },
        Filter: {
          vendor_id: '',
          offer_type: '',
        },
        Offers : [
          {
            name: "Buffet",
            value: "Buffet"
          },
          {
            name: "Single",
            value: "Single"
          },{
            name: "Subscription",
            value: "Subscription"
          },{
            name:"Bundle",
            value: "Bundle"
          }
        ],
        perPage:100,
        currentPage:1,
      }
    },
    computed: {
      ...mapState({
        items: (state) => state.subledger.items,
        isLoading: (state) => state.subledger.isLoading,
        isDownload: (state) => state.subledger.isDownload,
        successMessage: (state) => state.subledger.successMessage,
        errorMessage: (state) => state.subledger.errorMessage,
        totalRows: (state) => state.subledger.totalRows,
        vendors:(state) => state.vendors.items,
        isLoadingSearch:(state)=> state.vendors.isLoading,
      })
    },
    watch: {
      currentPage: function() {
        this.actionSearch()
      },
      errorMessage: function() {
        if (this.errorMessage) {
          this.messageAlert('error', this.errorMessage);
        }
      },
      isDownload: function() {
        if (!this.isDownload && this.successMessage) {
          this.messageAlert('success', this.successMessage);
        }
      },
      $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Subsidiary Ledger | Content Management System Ebooks Gramedia.com';
        }
      },
    },
    methods: {
      //
      ...mapActions('subledger',['fetchReport', 'sendReportByPeriod']),
      ...mapActions("vendors",["fetchVendors","searchVendors"]),
      validateDate() {
        console.log(this.range)
        if(!this.range.start || !this.range.end){
           this.$swal({
            toast: "true",
            position: "top-end",
            icon: "error",
            title: "Date range is required",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        } else {
          return true;
        }
      },
      extractQuery (){
        let query  = this.$route.query
        if (query) {
          return {
            from: moment(query.from).format("YYYY-MM-DD"),
            to: moment(query.to).format("YYYY-MM-DD"),
            page: this.currentPage,
            limit: this.perPage,
            vendor_id: query.vendor_id ? query.vendor_id.id: false,
            offer_type: query.offer_type? query.offer_type : false,
          };
          
        }
        return {}
      },
      actionSearch(){
        let v = this.validateDate()
        let query  = this.extractQuery()
        if (v) {
          
          let payload = {
            from: moment(this.range.start).format("YYYY-MM-DD"),
            to: moment(this.range.end).format("YYYY-MM-DD"),
            page: this.currentPage,
            limit: this.perPage,
            vendor_id: this.Filter.vendor_id ? this.Filter.vendor_id.id: false,
            offer_type: this.Filter.offer_type ? this.Filter.offer_type : false,
          };
          let q
          if(payload.vendor_id && payload.offer_type) {
            q  = {from: payload.from, to: payload.to, vendor_id: payload.vendor_id, offer_type: payload.offer_type }
          } else if(payload.offer_type) {
            q  = {from: payload.from, to: payload.to, offer_type: payload.offer_type }
          } else if(payload.vendor_id) {
            q  = {from: payload.from, to: payload.to, vendor_id: payload.vendor_id }
          } else {
            q  = {from: payload.from, to: payload.to }
          }
          console.log("banding kan ",payload,query)
          if (JSON.stringify(query) != JSON.stringify(payload)) {
            this.fetchReport(payload)
            this.$router.push({ path :'subledger', query :q})
          } else {
            this.fetchReport(payload)
          }
          
        }
      },
      actionGetVendor(){
        let payload = {
          page: 1,
          limit: 1000,
        }
        this.fetchVendors(payload)
      },
      customLabel({ name }) {
        if (name) {
          return name;
        } else {
          return "Type Here";
        }
      },
      existQuery() {
        let q  = this.$route.query
        if(q) {
          this.range.start = new Date(q.from).toString()
          this.range.end = new Date(q.to).toString()
        }
        if (q.from && q.to && q.vendor_id && q.offer_type) {
          let payload = {
            from: q.from,
            to: q.to,
            page: 1,
            limit: this.perPage,
            vendor_id: q.vendor_id ? q.vendor_id : false,
            offer_type: q.offer_type ? q.offer_type : false,
          };
          let offer_type = this.Offers.find(v => v.value == q.offer_type)
          let vendor_id = this.vendors.find(v => v.id == q.vendor_id)
          
          this.Filter.offer_type = offer_type.value
          this.Filter.vendor_id=vendor_id
          this.range.start = q.from
          this.range.end = q.to
          this.fetchReport(payload)
        } else if (q.from && q.to && q.vendor_id && !q.offer_type) {
          let payload = {
            from: q.from,
            to: q.to,
            page: 1,
            limit: this.perPage,
            vendor_id: q.vendor_id,
            offer_type: q.offer_type ? q.offer_type : false,
          };
          this.Filter.vendor_id = this.vendors.find(v => v.id == q.vendor_id)
          this.fetchReport(payload)
        } else if (q.from && q.to && q.offer_type && !q.vendor_id) {
          let payload = {
            from: q.from,
            to: q.to,
            page: 1,
            limit: this.perPage,
            vendor_id: q.vendor_id ? q.vendor_id : false,
            offer_type: q.offer_type,
          };
          this.Filter.offer_type = this.Offers.find(v => v.value == q.offer_type)
          this.fetchReport(payload)
        } else if (q.from && q.to ) {
          let payload = {
            from: q.from,
            to: q.to,
            page: 1,
            limit: this.perPage,
            vendor_id: q.vendor_id ? q.vendor_id : false,
            offer_type: q.offer_type,
          };
         
          this.fetchReport(payload)
        }
      },
      downloadReport() {
        if (!this.Filter.vendor_id) {
          this.messageAlert("error", "Publisher is required");
          return false;
        }
        const payload = {
          vendor_id: this.Filter.vendor_id.id,
          offer_type: (this.Filter.offer_type ?? '').toLowerCase(),
          from: moment(this.range.start).format("YYYY-MM-DD"),
          to: moment(this.range.end).format("YYYY-MM-DD"),
        }
        this.sendReportByPeriod(payload);
      },

      messageAlert(icon, title, timer=3000) {
        this.$swal({
            toast: "true",
            position: "top-end",
            icon,
            title,
            showConfirmButton: false,
            timer,
            timerProgressBar: true,
          });
      },
      asyncFind(query) {
        if (query.length > 3) {
					this.isLoadingSearch = true;
					setTimeout(() => {
						let payload = {
							q: query,
              limit:10
						};
						this.searchVendors(payload)
					}, 800);
				}
      }
    }
  }
</script>
<style scoped>
.books {
  font-family: "Avenir Next","effra", Helvetica, Arial, sans-serif;
}
</style>